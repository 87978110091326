/* eslint-disable */
import Vue from 'vue';
import Vuex from "vuex";
import store from '../store';
import VueRouter from 'vue-router'
import Home from '../components/Home';
import News from '../components/News';
import Rules from '../components/Rules';
import Archives from '../components/Archives';
import Palmares from '../components/Palmares';
import LeaderBoard from '../components/LeaderBoard';
import Login from '../components/Login';
import Profile from '../components/Profile';
import Post from '../components/Post';
import InfosPremium from '../components/InfosPremium';
import ReadAll from '../components/ReadAll';
import Jury from '../components/Jury';
import Author from '../components/Author';
import ReadMode from '../components/ReadMode';

Vue.use(VueRouter)
Vue.use(Vuex)

const routes = [
    {
        path: '*',
        name: 'homeAll',
        component: Home
    },
    {
        path: '/',
        name: 'home',
        component: Home
    },
    {
        path: '/news/:id',
        name: 'news',
        component: News
    },
    {
        path: '/reglement',
        name: 'rules',
        component: Rules
    },
    {
        path: '/archive',
        name: 'archives',
        component: Archives
    },
    {
        path: '/palmares/:id',
        name: 'palmares',
        component: Palmares
    },
    {
        path: '/classement',
        name: 'classement',
        component: LeaderBoard
    },
    {
        path: '/login',
        name: 'login',
        component: Login
    },
    {
        path: '/profil',
        name: 'profile',
        component: Profile
    },
    {
        path: '/post',
        name: 'post',
        component: Post
    },
    {
        path: '/infos-premium',
        name: 'info-premium',
        component: InfosPremium
    },
    {
        path: '/read-all/:id',
        name: 'read-all',
        component: ReadAll
    },
    {
        path: '/jugement',
        name: 'jugement',
        component: Jury
    },
    {
        path: '/auteur/:id',
        name: 'author',
        component: Author
    },
    {
        path: '/read-mode',
        name: 'readMode',
        component: ReadMode
    }
];

const router = new VueRouter({
    routes
});

router.beforeEach((to, from, next) => {
    window.scrollTo(0, 0);
    Vue.prototype.$http.get(
        '/checkConnexion'
    ).then(response => {
        if (response.data.is_connected) {
            store.commit('connect');
            if(response.data.is_premium) {
                store.commit('setPremium');
            } else {
                store.commit('unsetPremium');
            }
            if(to.name == 'login') {
                next('home');
            } else {
                next();
            }
        } else {
            store.commit('disconnect');
            store.commit('unsetPremium');
        }
    }).catch(response => {
        return next();
    });

    next();
});

export default router
