<template>
  <v-container v-if="profile">
    <v-row>
      <v-col cols="12">
        <h1>Votre profil</h1>
      </v-col>
    </v-row>
    <v-row v-if="profile && profile.is_jury != '0'">
      <v-col cols="12">
        <v-btn class="bkg-orange" to="/jugement"><span class="color-white">Jugez !</span></v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="8">
        <v-sheet
            :elevation="24"
            class="mx-auto pa-5"
        >
          <v-row>
            <v-col cols="12" class="d-block d-xs-block d-sm-block d-md-none text-right">
              <v-btn
                class="bkg-orange"
                @click="dialog = true"
              >
                <span class="color-white">
                  <v-icon>mdi-square-edit-outline</v-icon>
                  Editer
                </span>
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" lg="2" class="text-center">
              <v-avatar
                  size="120"
                  color="grey"
              >
                <v-img :src="profile.image ? 'https://tavernedesspores.fr/uploads/' + profile.image : 'https://tavernedesspores.fr/uploads/default.png'"></v-img>
              </v-avatar>
              <v-btn class="bkg-orange mt-2 d-none d-lg-flex" @click="changeCover()"><span class="color-white">Changer</span></v-btn>
            </v-col>
            <v-col cols="12" lg="10">
              <v-row>
                <v-col cols="12">
                  <div class="d-flex justify-space-between">
                    <h2>{{ profile.name }}</h2>
                    <v-btn
                      class="bkg-orange d-none d-md-block"
                      @click="dialog = true"
                    >
                      <span class="color-white">
                        <v-icon>mdi-square-edit-outline</v-icon>
                        Editer
                      </span>
                    </v-btn>
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <strong>Date d'inscription :</strong> {{ profile.created_at }}
                  <v-divider></v-divider>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <strong>E-mail :</strong> {{ profile.login }}
                  <v-divider></v-divider>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <strong>Nombre de Spores publiées :</strong> {{ profile.nb_txt }}
                  <v-divider></v-divider>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <strong>Points pour la saison en cours :</strong> {{ profile.points }}
                  <v-divider></v-divider>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-switch
                    class="pb-0 mb-0"
                    inset
                    v-model="profile.is_public"
                    label="Rendre mon profil public"
                    color="green"
                    hint="Si vous rendez votre profil public, vous pourrez le partager et tout le monde pourra le visualiser."
                    persistent-hint
                    @change="changePublic()"
                  ></v-switch>
                  <p class="text-center" style="color: #777;font-size: small">Pour partager votre profil public, copiez ce lien : <a style="color: #777;font-size: small" target="_blank" :href="'/#/auteur/' + profile.id_user">https://tavernedesspores.fr/#/auteur/{{ profile.id_user }}</a></p>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-sheet>
      </v-col>
      <v-col cols="12" md="4">
        <v-sheet
            :elevation="24"
            class="mx-auto pa-5"
        >
          <v-row>
            <v-col cols="12">
              <v-img src="../assets/premium.png" width="190" class="mx-auto d-flex mb-sm-2 mb-md-4" transition="scale-transition"></v-img>
              <v-btn large block class="bkg-premium color-premium" v-show="$store.state.isPremium"><span @click="managePremium()">Gérer mon abonnement</span></v-btn>
              <v-btn x-large block class="bkg-premium color-premium" @click="goPremium()" v-show="!$store.state.isPremium"><v-icon style="color:#424242">mdi-star-face</v-icon><span>Passer premium :)</span></v-btn>
            </v-col>
            <v-col cols="12">
              <v-btn large block class="bkg-orange" :to="'post'"><span class="color-white">Participer</span></v-btn>
            </v-col>
          </v-row>
        </v-sheet>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <h2>Mes informations publiques</h2>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-sheet
            :elevation="24"
            class="mx-auto pa-5"
        >
          <v-row>
            <v-col cols="12">
              <h3>A propos de moi</h3>
              <v-textarea
                counter
                outlined
                solo
                rows="7"
                background-color="light-blue"
                hint="Entrez ici une courte présentation de vous et de votre oeuvre, qui sera consultable sur votre profil public."
                persistent-hint
                v-model="profile.description"
                :rules="rules"
              ></v-textarea>
              <div class="d-flex justify-end">
                <v-btn class="bkg-orange" @click="updateDesc()"><span class="color-white">Enregistrer</span></v-btn>
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <h3>Mes liens</h3>
              <v-sheet
                  :elevation="24"
                  class="mx-auto pa-5"
                  color="blue lighten-4"
              >
                Vous pouvez ici créer des liens qui seront affichés sur votre profil public. Vous pouvez ainsi partager votre travail en centralisant vos informations en un seul endroit. Créez votre carte de visite d'auteur !
              </v-sheet>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="5">
              <label>
                Titre du lien
                <v-text-field required dense label="Titre du lien" outlined solo v-model="newLink.title"></v-text-field>
              </label>
            </v-col>
            <v-col cols="12" md="5">
              <label>
                Url du lien
                <v-text-field required dense label="Lien" outlined solo v-model="newLink.link"></v-text-field>
              </label>
            </v-col>
            <v-col cols="12" md="2" class="d-flex align-center">
              <v-btn block class="bkg-orange" @click="createLink()"><span class="color-white">Créer</span></v-btn>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row v-if="userLinks.length">
            <v-col cols="12">
              <h4>Mes liens existants</h4>
            </v-col>
          </v-row>
          <v-row v-if="userLinks.length">
            <v-col cols="12">
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">
                        Titre du lien
                      </th>
                      <th class="text-left">
                        Lien
                      </th>
                      <th class="text-left"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="link in userLinks"
                      :key="link.id"
                    >
                      <td>{{ link.title }}</td>
                      <td><a :href="link.link" target="_blank">{{ link.link }}</a></td>
                      <td class="text-center">
                        <v-btn icon color="red" @click="deleteLink(link.id)"><v-icon>mdi-delete-forever</v-icon></v-btn>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>
        </v-sheet>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <h2 class="mb-2">Mes Trophées</h2>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <Trophy :trophies="profile.hf"/>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <h2 class="mb-2">Mes Spores</h2>
      </v-col>
    </v-row>
    <div v-if="userSpores && userSpores.length">
      <v-row v-for="spore in userSpores" :key="spore.id">
        <v-col cols="12">
          <v-sheet
              :elevation="24"
              class="mx-auto pa-5"
          >
            <v-row>
              <v-col cols="12">
                <v-chip
                    class="bkg-orange text-capitalize"
                >
                  <span class="color-white">{{  spore.name }}</span>
                </v-chip>
                <v-chip
                    class="ml-2 bkg-orange text-capitalize"
                >
                  <span class="color-white" v-html="spore.theme"></span>
                </v-chip>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <h3 class="mb-0">{{ spore.title }}</h3>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <small>{{ spore.created_at }}</small>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <p class="text-justify long-text" v-html="spore.text"></p>
              </v-col>
            </v-row>
            <v-row v-if="$store.state.isPremium">
              <v-col cols="12" class="text-right">
                <v-chip
                    class="bkg-orange text-capitalize"
                >
                  <span class="color-white">Note du jury : {{  spore.note && spore.note>3 ? spore.note : "Inconnue" }}</span>
                </v-chip>
              </v-col>
            </v-row>
          </v-sheet>
        </v-col>
      </v-row>
      <v-row v-if="showMore">
        <v-col cols="12" class="text-center">
          <v-btn class="bkg-orange color-white" @click="moreSpore()">Plus...</v-btn>
        </v-col>
      </v-row>
    </div>
    <v-row v-if="!userSpores.length">
      <v-col cols="12" class="long-text text-center mb-15 no-spore">
        Aucune Spore pour le moment...Il est temps de s'y mettre :)
      </v-col>
    </v-row>
    <input type="file" ref="file" style="display: none" @change="uploadCover()">
    <v-dialog
      v-model="dialog"
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <h2>Edition du profil</h2>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-col cols="12">
              <v-text-field
                  v-model="newPassword"
                  type="password"
                  label="Nouveau mot de passe"
                  required
                  outlined
                  solo
              ></v-text-field>
              <v-btn block class="bkg-orange" @click="changePassword()"><span class="color-white">Changer mot de passe</span></v-btn>
            </v-col>
            <hr />
            <v-col cols="12">
              <v-text-field
                  v-model="profile.name"
                  type="text"
                  label="Nouveau nom"
                  required
                  outlined
                  solo
              ></v-text-field>
              <v-btn block class="bkg-orange" @click="changeName()"><span class="color-white">Changer mon nom</span></v-btn>
            </v-col>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            text
            @click="dialog = false"
          >
            Fermer
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import Trophy from './Trophy.vue';
export default {
  name: 'App',

  components: {
    Trophy
  },

  data: () => ({
    profile: null,
    newPassword: null,
    userSpores: [],
    userLinks: [],
    newLink: {title: "", link: ""},
    offsetSpores: 0,
    showMore: true,
    stripe: null,
    dialog: false,
    rules: [v => v.length <= 1500 || 'Maximum 1500 caractères'],
  }),
  mounted() {
    this.stripe = window.Stripe('pk_live_qLaA76OjwjlHfbFTmIu9Mhof');
    this.$http.get('/getProfile').then(response => {
      this.profile = response.data.profile;
      this.profile.is_public = (this.profile.is_public === '1');
    }).catch(() => {

    });
    this.getSpores(this.offsetSpores);
    this.getLinks();
  },
  methods: {
    getSpores: function(offset) {
      this.$http.get('/getSporeByUser/' + offset).then(response => {
        if(!response.data.length) {
          this.showMore = false;
        }
        this.userSpores = this.userSpores.concat(response.data);
      }).catch(() => {

      });
    },
    getLinks: function() {
      this.$http.get('/getUserLinks').then(response => {
        this.userLinks = response.data;
      }).catch(() => {

      });
    },
    moreSpore: function() {
      this.offsetSpores += 3;
      this.getSpores(this.offsetSpores);
    },
    changeCover: function() {
      this.$refs.file.click();
    },
    uploadCover: function() {
      if(this.$refs.file.files.length) {
        let formData = new FormData();
        formData.append('file', this.$refs.file.files[0]);
        this.$http.post( '/upload',
            formData
        ).then(response => {
          if(response.data.success) {
            this.$notify({
              group: 'spore',
              title: 'OK',
              text: 'Photo de profil mise à jour',
              type: 'success'
            });
            this.profile.image = response.data.new_filename;
          } else {
            this.$notify({
              group: 'spore',
              title: 'Erreur...',
              text: 'Désolé, une erreur est survenue :(',
              type: 'error'
            });
          }
        }).catch(function(){
          this.$notify({
            group: 'spore',
            title: 'Erreur...',
            text: 'Désolé, une erreur est survenue :(',
            type: 'error'
          });
        });
      }
    },
    changePassword: function() {
      if(this.newPassword) {
        this.$http.post('/setPassword', {'password': this.newPassword}).then(response => {
          if(response.data.success) {
            this.$notify({
              group: 'spore',
              title: 'OK',
              text: 'Mot de passe mis à jour',
              type: 'success'
            });
            this.dialog = false;
          } else {
            this.$notify({
              group: 'spore',
              title: 'Erreur...',
              text: 'Désolé, une erreur est survenue :(',
              type: 'error'
            });
          }
          this.newPassword = null;
        }).catch(() => {
          this.$notify({
            group: 'spore',
            title: 'Erreur...',
            text: 'Désolé, une erreur est survenue :(',
            type: 'error'
          });
          this.newPassword = null;
        });
      }
    },
    changeName: function() {
      if(this.profile.name) {
        this.$http.post('/setName', {'name': this.profile.name}).then(response => {
          if(response.data.success) {
            this.$notify({
              group: 'spore',
              title: 'OK',
              text: 'Nom mis à jour',
              type: 'success'
            });
            this.dialog = false;
          } else {
            this.$notify({
              group: 'spore',
              title: 'Erreur...',
              text: 'Désolé, une erreur est survenue :(',
              type: 'error'
            });
          }
        }).catch(() => {
          this.$notify({
            group: 'spore',
            title: 'Erreur...',
            text: 'Désolé, une erreur est survenue :(',
            type: 'error'
          });
        });
      }
    },
    changePublic: function() {
      this.$http.post('/setPublic', {'public': this.profile.is_public}).then(response => {
        if(response.data.success) {
          this.$notify({
            group: 'spore',
            title: 'OK',
            text: 'Visibilité du profil mise à jour',
            type: 'success'
          });
          this.dialog = false;
        } else {
          this.$notify({
            group: 'spore',
            title: 'Erreur...',
            text: 'Désolé, une erreur est survenue :(',
            type: 'error'
          });
        }
      }).catch(() => {
        this.$notify({
          group: 'spore',
          title: 'Erreur...',
          text: 'Désolé, une erreur est survenue :(',
          type: 'error'
        });
      });
    },
    updateDesc: function() {
      this.$http.post('/setDescr', {'descr': this.profile.description}).then(response => {
        if(response.data.success) {
          this.$notify({
            group: 'spore',
            title: 'OK',
            text: 'Description mise à jour',
            type: 'success'
          });
          this.dialog = false;
        } else {
          this.$notify({
            group: 'spore',
            title: 'Erreur...',
            text: 'Désolé, une erreur est survenue :(',
            type: 'error'
          });
        }
      }).catch(() => {
        this.$notify({
          group: 'spore',
          title: 'Erreur...',
          text: 'Désolé, une erreur est survenue :(',
          type: 'error'
        });
      });
    },
    createLink: function() {
      if(this.newLink.title && this.newLink.link) {
        this.$http.post('/createLink', this.newLink).then(response => {
          if(response.data.success) {
            this.$notify({
              group: 'spore',
              title: 'OK',
              text: 'Lien créé avec succès',
              type: 'success'
            });
            this.newLink.title = "";
            this.newLink.link = "";
            this.getLinks();
          } else {
            this.$notify({
              group: 'spore',
              title: 'Erreur...',
              text: 'Désolé, une erreur est survenue :(',
              type: 'error'
            });
          }
        }).catch(() => {
          this.$notify({
            group: 'spore',
            title: 'Erreur...',
            text: 'Désolé, une erreur est survenue :(',
            type: 'error'
          });
        });
      }
    },
    deleteLink: function(id) {
      if(confirm('Etes-vous sûr de vouloir supprimer ce lien ?')) {
        this.$http.get('/deleteLink/' + id).then(() => {
          this.$notify({
            group: 'spore',
            title: 'OK',
            text: 'Lien supprimé avec succès',
            type: 'success'
          });
          this.getLinks();
        }).catch(() => {
          this.$notify({
            group: 'spore',
            title: 'Erreur...',
            text: 'Désolé, une erreur est survenue :(',
            type: 'error'
          });
        });
      }
    },
    goPremium: function() {
      this.$http.post('/stripe-init', {priceId: 'price_1HmGsdEnHQ7JqNxairu7YBhh'}).then(response => {
        this.stripe.redirectToCheckout({
              sessionId: response.data.sessionId
            }).then(() => {

        });
      }).catch(() => {

      });
    },
    managePremium: function() {
      this.$http.get('/stripe-portal').then(response => {
        if(response.data.url) {
          document.location.replace(response.data.url);
        }
      }).catch(() => {

      });
    }
  }
};
</script>
<style scoped>
.no-spore {
  background: #efdba2;
  padding: 15px;
  border-radius: 5px;
}
.bkg-premium {
  background: #c5cc25 !important;
}
.color-premium {
  color: #000000;
}
</style>