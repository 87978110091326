<template>
  <v-container v-if="allData && allData.contest" class="pa-5">
    <v-row>
      <v-col cols="12">
        <h1>Intégralité {{ allData.contest.name }}</h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="theme">
        <p class="text-center">Aucun texte n'a été corrigé / retouché.</p>
        <p class="text-center">Soyez conscients que les notes affichées ne reflétent pas forcément le classement final. Elles sont issues de la préselection, avant délibération finale !</p>
        <p class="text-center">Si aucune note n'est présente, c'est que le concours est trop vieux et n'utilisait pas encore le système de note ou que la Spore a eu une note inférieure à 3.</p>
      </v-col>
    </v-row>
    <v-row v-if="allData.contest">
      <v-col cols="12" md="4" class="text-center">
        <v-card elevation="24" outlined>Nb de Spores total : <br/><span class="long-text">{{ allData.spores_data.nb_spores }}</span></v-card>
      </v-col>
      <v-col cols="12" md="4" class="text-center">
        <v-card elevation="24" outlined>Nb de participants uniques : <br/><span class="long-text">{{ allData.spores_data.nb_participant }}</span></v-card>
      </v-col>
      <v-col cols="12" md="4" class="text-center">
        <v-card elevation="24" outlined>Moyenne de Spores par participants : <br/><span class="long-text">{{ allData.spores_data.moy }}</span></v-card>
      </v-col>
    </v-row>
    <div v-if="allData.contest" class="mt-3">
      <v-row class="" v-for="spore in allData.spores_data.spores"
             :key="spore.id" >
        <v-card elevation="24" class="w-100 mb-5">
          <v-col cols="12">
            <h3>
              {{spore.title}} <span v-if="spore.is_public == '0'"><small>par {{ spore.name }}</small></span><span v-else><small>par <router-link title="Voir le profil" class="author-link" :to="'/auteur/' + spore.id_user">{{ spore.name }}</router-link></small></span>
            </h3>
          </v-col>
          <v-col cols="12">
            <div class="long-text text-justify" v-html="spore.text"></div>
          </v-col>
          <v-col cols="12" class="text-right">
            <v-chip class="bkg-orange">
              <span class="color-white">Note : {{ spore.note && spore.note>=3 ? spore.note : "Inconnue" }}</span>
            </v-chip>
          </v-col>
        </v-card>
      </v-row>
      <v-row v-if="allData.spores_data.spores.length">
        <v-col cols="12" class="text-center">
          <v-pagination
              v-model="page"
              :length="allData.spores_data.nb_pages"
              :total-visible="10"
              @input="changePage"
              color="#6f5248"
          ></v-pagination>
        </v-col>
      </v-row>
    </div>
    <div v-if="!allData.spores_data.spores.length">
      <v-col cols="12" class="theme">
        <p>Ce concours ne contient visiblement aucune spores, ou n'a pas encore été noté !</p>
      </v-col>
    </div>
  </v-container>
</template>
<script>
export default {
  name: 'App',

  components: {
  },

  data: () => ({
    allData: null,
    page: 1
  }),
  mounted() {
    this.$http.post('/getAllSpores', {'id': this.$route.params.id, 'offset': 0}).then(response => {
      this.allData = response.data;
      if(!this.allData.contest) {
        this.$router.push('/home');
      }
    }).catch(() => {

    });
  },
  methods: {
    changePage: function(newPage) {
      this.$http.post('/getAllSpores', {'id': this.$route.params.id, 'offset': ((newPage-1) * 30)}).then(response => {
        this.allData = response.data;
        window.scrollTo(0, 0);
      }).catch(() => {

      });
    }
  }
};
</script>
<style scoped>
span>small {
  color: #777777;
  font-weight: 400;
  font-family: 'Oswald';
  text-transform: uppercase;
  font-size: 100%;
}
.author-link {
  font-family: 'Oswald';
}
</style>