<template>
  <v-app>
    <div>
      <v-app-bar
          fixed
          dense
          v-if="$router.currentRoute.name !== 'author' || $store.state.isConnected"
      >
        <router-link to="/" class="text-decoration-none">
          <v-toolbar-title class="d-flex align-center logo-title">
            <v-img
                alt="TDS Logo"
                class="mx-2 d-none d-md-flex"
                contain
                src="./assets/logo.svg"
                transition="scale-transition"
                max-height="40"
                max-width="40"
            /> Taverne des Spores
          </v-toolbar-title>
        </router-link>
        <v-spacer></v-spacer>
        <ul class="align-content-end menu d-none d-md-flex">
          <li v-for="item in menu" :key="item.title" :class="{'d-none': (item.hide_if_connected && $store.state.isConnected) || (item.hide_if_not_connected && !$store.state.isConnected)}">
            <router-link :to="item.link" exact class="menu-item">{{ item.title }} <v-icon small v-if="item.icon && $router.currentRoute.name !== 'readMode'" class="ml-1 menu-icon" color="#dacb45">{{ item.icon }}</v-icon></router-link>
          </li>
          <li v-if="$store.state.isConnected" class="pointer" @click="disconnect()">
              <v-icon style="color: #6f5248 !important">mdi-power-standby</v-icon>
          </li>
        </ul>
        <v-menu class="d-flex d-md-none">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                v-bind="attrs"
                v-on="on"
                class="d-flex d-md-none"
            >
              <span class="color-orange">
                <v-icon>mdi-menu</v-icon>
              </span>
            </v-btn>
          </template>
          <v-list>
            <v-list-item v-for="item in menu" :key="item.title" :class="{'d-none': (item.hide_if_connected && $store.state.isConnected) || (item.hide_if_not_connected && !$store.state.isConnected)}">
              <router-link :to="item.link" class="menu-item">{{ item.title }} <v-icon small v-if="item.icon && $router.currentRoute.name !== 'readMode'" class="ml-1 menu-icon" color="#dacb45">{{ item.icon }}</v-icon></router-link>
            </v-list-item>
            <v-list-item>
              <v-icon>mdi-power-standby</v-icon>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-app-bar>
      <div class="text-center pa-5 pt-1 wave1" v-if="$router.currentRoute.name !== 'author' && $router.currentRoute.name !== 'readMode'">
          <v-img
              alt="TDS Logo"
              style="margin:auto"
              contain
              :src="$router.currentRoute.name != 'info-premium' ? require('./assets/logo.svg') : require('./assets/premium.png')"
              transition="scale-transition"
              width="250"
          />
        <h1 v-if="$router.currentRoute.name == 'home'" class="bkged">Concours de court !</h1>
        <h5 v-if="$router.currentRoute.name == 'home'" class="bkged">Depuis 2018</h5>
      </div>
      <v-main>
       <transition name="slide-fade">
          <router-view></router-view>
       </transition>
        <cookie-law buttonText="OK !" buttonClass="bkg-orange color-white pa-2 rounded">
          <div slot="message">
            En poursuivant votre navigation sur ce site, vous acceptez l’utilisation de cookies. Ceux-ci nous permettent de vous proposer une expérience personnalisée.
          </div>
        </cookie-law>
      </v-main>
      <v-card height="150" v-if="$router.currentRoute.name !== 'login'">
        <v-footer
            padless
        >
          <v-card
              tile
              class="text-center w-100"
              elevation="24"
              style="border-top: 2px solid #6f5248"
          >
            <v-card-text>
              <v-btn
                  class="mx-4"
                  icon
                  outlined
                  @click="openTwitter()"
              >
                <v-icon size="24px" color="blue">
                  mdi-twitter
                </v-icon>
              </v-btn>
            </v-card-text>

            <v-card-text class="white--text pt-0">
              <p>Des idées, toute personne qui écrit régulièrement en a. Malheureusement, nombreux sont les projets ambitieux commencés et jamais terminés.</p>
              <p>Néanmoins, la régularité dans l'écriture est primordiale pour entretenir la flamme de la créativité.</p>
              <p>La solution ?</p>
              <p>Le concours d'écriture de la Taverne des Spores !</p>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-text>
              <div>
                <img src="./assets/shrooms.png" width="150px"/>
              </div>
              <div class="text-center">
                <span>2018 - {{ new Date().getFullYear() }} — <strong>Taverne des Spores</strong></span>
              </div>
            </v-card-text>
          </v-card>
        </v-footer>
      </v-card>
    </div>
    <notifications group="spore" position="top center"/>
  </v-app>
</template>

<script>
import CookieLaw from 'vue-cookie-law'
export default {
  name: 'App',

  components: {
    CookieLaw
  },

  data: () => ({
    menu: [
      {
        'title': 'Accueil',
        'link' : '/',
        'hide_if_connected': false,
        'hide_if_not_connected': false
      },
      {
        'title': 'Connexion / Inscription',
        'link' : '/login',
        'hide_if_connected': true,
        'hide_if_not_connected': false
      },
      {
        'title': 'Mode lecture',
        'link' : '/read-mode',
        'hide_if_connected': false,
        'hide_if_not_connected': false,
        icon: 'mdi-star'
      },
      {
        'title': '[Participer]',
        'link' : '/post',
        'hide_if_connected': false,
        'hide_if_not_connected': true
      },
      {
        'title': 'Profil',
        'link' : '/profil',
        'hide_if_connected': false,
        'hide_if_not_connected': true
      },
      {
        'title': 'Archives',
        'link' : '/archive',
        'hide_if_connected': false,
        'hide_if_not_connected': false
      },
      {
        'title': 'Règlement',
        'link' : '/reglement',
        'hide_if_connected': false,
        'hide_if_not_connected': false
      },
      {
        'title': 'Classement',
        'link' : '/classement',
        'hide_if_connected': false,
        'hide_if_not_connected': false
      }
    ]
  }),
  methods: {
    openTwitter: function() {
      window.open('https://x.com/DesSpores', '_blank');
    },
    disconnect: function() {
      this.$http.get('/disconnect').then(() => {
        this.$store.commit('disconnect');
        this.$router.push('/home');
      }).catch(() => {

      });
    }
  }
};
</script>
<style scoped>
  .wave1 {
    background: #ffffff;
    background-image: url('https://tavernedesspores.fr/img/book.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-attachment: fixed;
    margin-top: 50px;
  }
  .v-toolbar__title {
    font-size: 1.5rem;
    line-height: 1.5;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-transform: uppercase;
    font-family: 'Oswald';
    font-weight: 700;
    color: #6f5248;
  }
  .bkged {
    background: #ffffff6d;
    /* border-radius: 150px; */
  }
  .Cookie--bottom {
    background: #DFCCAB;
  }
</style>
