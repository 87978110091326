<template>
    <div v-if="profile" :style="$store.state.isConnected ? 'margin-top: 75px' : ''">
        <header id="x">
            <div class="header-container">
                <h1>{{ profile.profile.name }}</h1>
                <small>Auteur sur la <a href="./">Taverne des Spores</a></small>
            </div>
        </header>
        <div class="content">
            <section>
                <div class="d-flex">
                    <div style="padding-bottom: 75%;"></div>
                    <div v-if="profile.profile.image" class="autor-image" :style="'background-image: url(\'https://tavernedesspores.fr/uploads/' + profile.profile.image + '\');'"></div>
                    <div v-else class="autor-image" style="background-image: url('https://tavernedesspores.fr/uploads/default.png');"></div>
                </div>
                <h1>A propos de moi</h1>
                    <p v-if="profile.profile.description" v-html="profile.profile.description"></p>
                    <p v-else class="text-center"><b>{{ profile.profile.name }}</b> n'a pas encore rempli son profil.</p>
            </section>
            <section v-if="profile.links.length">
                <h1>Où me trouver</h1>
                <div class="spore-container" v-for="(link, index) in profile.links" :key="index">
                    <p class="text-center"><a :href="link.link" target="_blank">{{ link.title }}<span class="mdi mdi-arrow-right" style="margin-left: 15px"></span></a></p>
                    <hr  />
                </div>
            </section>
            <section>
                <h1>Mes trophées sur la Taverne</h1>
                <Trophy :trophies="profile.hf"/>
            </section>
            <section>
                <h1>Mes Spores</h1>
                <div v-if="profile.spores.length">
                    <div class="spore-container" v-for="(spore, index) in profile.spores" :key="index">
                        <div class="d-flex" style="margin-left: 15px;gap: 5px;">
                            <div class="chip">Concours : {{ spore.name }}</div>
                            <div class="chip">Thème : {{ spore.theme }}</div>
                        </div>
                        <h1>{{ spore.title }}</h1>
                        <h4>{{ spore.created_at }}</h4>
                        <p v-html="spore.text"></p>
                        <hr  />
                    </div>
                    <v-row v-if="showMore">
                        <v-col cols="12" class="text-center">
                        <v-btn class="bkg-orange color-white" @click="moreSpore()">Plus...</v-btn>
                        </v-col>
                    </v-row>
                </div>
                <p v-else class="text-center"><b>{{ profile.profile.name }}</b> n'a encore publié aucune Spore.</p>
            </section>
        </div>
    </div>
</template>
<script>
import Trophy from './Trophy.vue';
export default {
name: 'Author',
components: {
    Trophy
},

data: () => ({
    profile: null,
    offsetSpores: 0,
    showMore: true
}),
mounted() {
    this.$http.get( '/getPublicProfile/' + this.$route.params.id).then(response => {
    if(response.data.success) {
        this.profile = response.data.data;

        setTimeout(() => {
            const header = document.getElementById("x");
            if (header) {
                header.style.backgroundAttachment = "scroll";
                setTimeout(() => {
                    header.style.backgroundAttachment = "fixed"; // Réaffiche après un petit délai
                }, 50);
            }
        }, 300);

    } else {
        this.$router.push('/home');
    }
    this.isLoading = false;
    }).catch(function(response){
    this.$notify({
        group: 'spore',
        title: 'Erreur...',
        text: response.data.message,
        type: 'error'
    });
    this.isLoading = false;
    });
},
computed: {
    
},
methods:{
    moreSpore: function() {
      this.offsetSpores += 3;
      this.getSpores(this.offsetSpores);
    },
    getSpores: function(offset) {
      this.$http.get('/getPublicSporeByUser/' + this.$route.params.id + '/' + offset).then(response => {
        if(!response.data.spores.length || response.data.spores.length < 3) {
          this.showMore = false;
        }
        this.profile.spores = this.profile.spores.concat(response.data.spores);
      }).catch(() => {

      });
    }
}
};
</script>
<style scoped>
header {
    background-image: url(https://tavernedesspores.fr/img/book.jpg);
    height: 400px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-attachment: fixed;
    box-shadow: 0px 0px 10px #bbb;
}

header h1 {
    text-transform: uppercase;
    margin: 0;
    font-size: 3em;
    line-height: normal;
}

.header-container {
    max-width: 1200px;
    margin: 0 auto;
}

section {
    display: flex;
    flex-flow: column wrap;
    align-items: center;
    justify-content: center;
    max-width: 1200px;
    padding-bottom: 20px;
    margin: 20px auto;
    box-shadow: 0px 0px 10px #bbb;
    background-color: #fff;
    border-radius: 6px;
}

.autor-image {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    width: 175px;
    border-radius: 150px;
    margin-top: -60px;
    margin-bottom: 10px;
    border: 3px solid #6f5248;
    box-shadow: 0px 0px 10px #777;
    background-color: #fff;
}

.spore-container {
    width: 100%;
}

.spore-container h1 {
    padding-left: 15px;
    font-size: 1.2em;
    margin-bottom: 5px;
    margin-top: 15px;
}

.spore-container h4 {
    padding-left: 15px;
    font-size: 11px;
    color: #959595;
    margin: 0;
    font-weight: 100;
}

p {
    text-align: justify;
    padding: 0 15px;
    line-height: 1.5rem;
}

h1 {
    font-family: 'Oswald';
    color: #6f5248;
    font-size: 1.5em;
    text-transform: none;
}

hr {
    margin: 0 15px;
    margin-bottom: 15px;
    color: #d1d1d1;
    border-color: #ffffff80;
}

a {
    color: #6f5248;
}

small,
small a {
    font-style: italic;
    color: #6b6b6b !important;
    font-size: small !important;
}

.chip {
    border-radius: 6px;
    font-size: 12px;
    background: #6f5248;
    color: white;
    padding: 5px 10px;
}

.content {
    margin-top: -315px;
}

@media screen and (max-width: 640px) {
    .content {
        margin-top: -250px;
    }
}

@media screen and (max-width: 380px) {
    .content {
        margin-top: -150px;
    }
}
</style>