<template>
  <v-container>
    <v-row>
      <v-col cols="12" lg="8">
        <v-card class="card-contest" elevation="24">
          <div class="d-flex flex-no-wrap">
            <v-avatar
                class="ma-3 rounded d-none d-md-flex"
                size="320"
                tile
            >
              <v-img v-if="currentContest && !currentContest.length" src="../assets/nocontest.png"></v-img>
              <v-img v-if="currentContest && currentContest.length" :src="'https://tavernedesspores.fr/img/' + currentContest[0].img"></v-img>
            </v-avatar>
            <div class="w-100">
              <v-card-title class="justify-sm-start justify-md-space-between">
                <v-chip class="bkg-theme d-none d-md-flex" label>
                  <v-icon left>
                    mdi-trophy
                  </v-icon>
                  <span class="fs-20">Concours Actuel</span>
                </v-chip>
                <v-btn
                    rounded
                    class="bkg-orange white--text"
                    v-if="currentContest && currentContest.length"
                    :to="$store.state.isConnected ? '/post' : '/login'"
                >
                  Participer
                </v-btn>
              </v-card-title>
              <v-card-text v-if="currentContest && currentContest.length">
                <div class="text-center">
                  <p class="fs-25 italic">- {{ currentContest[0].name }} -</p>
                </div>
                <div class="theme">
                  <p class="text-center color-orange d-none d-md-block theme-container">Thème :</p>
                  <p class="contest-theme text-center" v-html="'&ldquo;' + currentContest[0].theme + '&rdquo;'"></p>
                  <p class="text-center">Récompense : {{ currentContest[0].reward }}</p>
                  <p class="text-center mb-0">Début : {{ currentContest[0].start }}</p>
                  <p class="text-center mb-0">Fin : {{ currentContest[0].end }}</p>
                </div>
              </v-card-text>
              <v-card-text v-if="currentContest && !currentContest.length">
                <div class="no-contest-container">
                  <p class="contest-theme no-theme text-center">Aucun concours pour le moment. Revenez bientôt :)</p>
                </div>
              </v-card-text>
            </div>
          </div>
        </v-card>
      </v-col>
      <v-col cols="12" lg="4">
        <v-card height="350px" elevation="24" v-show="!$store.state.isPremium">
          <div class="d-flex bordered flex-no-wrap h-100">
            <div class="w-100 h-100 premium">
              <v-card-text>
                <v-img src="../assets/premium.png" width="220" class="mx-auto d-flex mb-sm-2 mb-md-4" transition="scale-transition"></v-img>
                <p class="text-justify">
                  <span class="fw-bold">Soutenez nous</span> pour 2€/mois et  accédez à l'intégralité des Spores soumises précédemment !
                </p>
                <p class="text-center">
                  <router-link to="/infos-premium">En savoir plus</router-link>
                </p>
              </v-card-text>
            </div>
          </div>
        </v-card>
        <v-card height="350px" elevation="24" v-show="$store.state.isPremium">
          <div class="d-flex bordered flex-no-wrap h-100">
            <div class="w-100 h-100 premium">
              <v-card-title>
                <h2 class="w-100 text-center color-orange">
                  La Taverne premium
                </h2>
              </v-card-title>
              <v-card-text>
                <v-img src="../assets/nain3.png" width="175" class="mx-auto d-flex" style="border-radius: 150px;margin-bottom: 10px"></v-img>
                <p class="text-center">Vous faites partie du club ;)</p>
                <p class="text-center">
                  <v-btn class="bkg-orange" rounded><span class="color-white" @click="managePremium()">Gérer mon abonnement</span></v-btn>
                </p>
              </v-card-text>
            </div>
          </div>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col sm="12" lg="12" class="mt-5">
        <v-card elevation="24" class="text-center">
          <a href="https://www.coollibri.com/" target="_blank">
            <v-img src="../assets/baniere-spores.jpg" width="100%" class="mx-auto d-flex"></v-img>
          </a>
        </v-card>
      </v-col>
    </v-row>
    <v-row id="row_news">
      <v-col sm="12" lg="9" class="mt-5">
        <h2 class="color-orange">Dernières News</h2>
      </v-col>
      <v-col sm="12" lg="3" class="mt-5 d-none d-lg-flex">
        <h2 class="text-center color-orange">TOP 10 actuel</h2>
      </v-col>
    </v-row>
    <v-row>
      <v-col sm="12" lg="8" class="bordered-right">
        <v-row>
          <v-col cols="12" v-for="news in lastNews" :key="news.id">
            <v-card
                class="mx-auto"
                outlined
                elevation="20"
            >
              <v-list-item three-line>
                <v-list-item-avatar
                    tile
                    size="100"
                    class="d-none d-md-flex"
                >
                  <v-img :src="'https://tavernedesspores.fr/img/' + news.image" style="border-radius: 4px"></v-img>
                </v-list-item-avatar>
                <v-list-item-content>
                  <div class="overline">
                    {{ news.publication_date }}
                  </div>
                  <v-list-item-title class="headline mb-1 color-orange">
                    {{  news.title }}
                  </v-list-item-title>
                  <v-list-item-subtitle>{{ news.short_text }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    outlined
                    rounded
                    text
                    class="bkg-orange white--text"
                    :to="'/news/' + news.id"
                >
                  LIRE
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <p class="my-5 text-center">
              <v-btn color="secondary" @click="getNews(-3)" v-show="newsOffset>0" class="mr-5">
                <v-icon
                    large
                >mdi-chevron-left</v-icon>
              </v-btn>
              <v-btn color="secondary" @click="getNews(3)">
                <v-icon
                    large
                >mdi-chevron-right</v-icon>
              </v-btn>
            </p>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="4" class="d-none d-lg-flex">
        <div style="max-height: 1250px; overflow-y : auto;width:100%" v-if="topTen.length">
          <v-simple-table dense>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">
                    Pos.
                  </th>
                  <th class="text-left" colspan="2">
                    Nom
                  </th>
                  <th class="text-left">
                    Points
                  </th>
                </tr>
              </thead>
              <tbody>
              <tr
                  v-for="(item, index) in topTen"
                  :key="index"
              >
                <td>
                  <div v-show="index==0" class="text-center"><img src="https://tavernedesspores.fr/img/gold_cup" width="25px"/></div>
                  <div v-show="index==1" class="text-center"><img src="https://tavernedesspores.fr/img/silver_cup" width="25px"/></div>
                  <div v-show="index==2" class="text-center"><img src="https://tavernedesspores.fr/img/bronze_cup" width="25px"/></div>
                  <div v-show="index>2" class="color-orange fs-18 text-center">{{ index + 1 }}°</div>
                </td>
                <td class="py-5 d-none d-md-table-cell" >
                  <v-avatar
                      color="white"
                      size="30"
                  >
                    <v-img :src="item.image ? 'https://tavernedesspores.fr/uploads/' + item.image : 'https://tavernedesspores.fr/uploads/default.png'"></v-img>
                  </v-avatar>
                </td>
                <td>
                  <span v-if="item.is_public == '0'" class="fs-15 spore-text">{{ item.name }}</span>
                  <router-link title="Voir le profil" class="fs-15 spore-text" v-else :to="'/auteur/' + item.id_user">{{ item.name }}</router-link>
                </td>
                <td><span class="fs-15 spore-text">{{ item.points }}</span></td>
              </tr>
              </tbody>
            </template>
          </v-simple-table>
          <div class="text-center">
            <a href="./#/classement">Voir le classement complet</a>
          </div>
        </div>
        <div v-else class="text-center spore-text fs-25">
          <p class="text-center spore-text fs-25">Pas encore de classement pour cette saison !</p>
          <a href="./#/classement">Voir les classements précédents</a>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="mt-5">
        <h2 class="text-center color-orange">Une Spore au hasard<br/><small class="d-none d-md-inline-block small-day">( chaque jour )</small></h2>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card elevation="24" v-if="!randomLoading" >
          <v-img :src="'https://tavernedesspores.fr/img/' + randomOne.contest_image" class="align-end" gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                 height="150">
              <v-card-title >
                <span class="theme" v-html="'Concours ' + randomOne.contest_name +', sur le thème <strong>' + randomOne.theme + '</strong>'"></span>
              </v-card-title>
          </v-img>
          <v-card-text>
            <p class="px-5 text-center warning-spore">La Spore ci-dessous est affichée sans retouche ni contrôle. Ses propos ainsi que les fautes n'engagent que son auteur (Bienvenue dans la vie du jury) ;)</p>
            <p class="headline color-orange text-capitalize">
              {{ randomOne.title }}
            </p>
            <p>
              {{ randomOne.created_at }}
            </p>
            <p class="text-justify fs-25 spore-text" v-html="randomOne.text ">
            </p>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <span class="fs-25 color-orange text-capitalize">{{ randomOne.name.indexOf('@')>0 ? 'Anonyme' : randomOne.name }}</span>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <div class="bkg-orange rounded mt-10 mb-5">
      <v-row>
        <v-col cols="12" class="text-center">
          <h2 class="color-white">Contact</h2>
        </v-col>
      </v-row>
      <v-row id="contact">
        <v-col cols="12">
          <div class="pa-5 color-white">
            <p class="color-white text-center">Vous avez trouvé un bug ? Vous souhaitez des informations ? Ou vous voulez tout simplement entrer en contact avec nous ? Remplissez le formulaire ci-dessous. Nous vous répondrons dans les plus brefs délais !</p>
            <v-form v-model="valid">
              <v-container>
                <v-row>
                  <v-col
                      cols="12"
                      md="4"
                  >
                    <v-text-field
                        v-model="contactInfo.name"
                        :counter="80"
                        label="Votre nom"
                        required
                        outlined
                        solo
                    ></v-text-field>
                  </v-col>

                  <v-col
                      cols="12"
                      md="4"
                  >
                    <v-text-field
                        v-model="contactInfo.email"
                        :rules="emailRules"
                        :counter="80"
                        label="Votre email"
                        required
                        outlined
                        solo
                    ></v-text-field>
                  </v-col>

                  <v-col
                      cols="12"
                      md="4"
                  >
                    <v-text-field
                        v-model="contactInfo.subject"
                        label="Sujet"
                        required
                        outlined
                        solo
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                      cols="12"
                  >
                    <v-textarea
                        label="Votre message"
                        v-model="contactInfo.message"
                        outlined
                        solo
                        style="caret-color: red !important;"
                    ></v-textarea>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="text-center">
          <v-btn @click="sendContact()">
            Envoyer
          </v-btn>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>
<script>
    export default {
        name: 'Home',
        data: () => ({
          valid: false,
          lastNews: null,
          randomOne: null,
          randomLoading: true,
          currentContest: null,
          contactInfo: {
            'name': null,
            'email': null,
            'subject': null,
            'message': null
          },
          emailRules: [
            v => /.+@.+/.test(v) || 'E-mail doit être valide',
          ],
          newsOffset: 0,
          topTen: []
        }),
        mounted() {
          this.getNews(0);

          this.$http.get('/random').then(response => {
            this.randomOne = response.data.spore;
            this.randomLoading = false;
          }).catch(() => {
            this.randomLoading = false;
          });

          this.$http.get('/getContests').then(response => {
            this.currentContest = response.data;
          }).catch(() => {

          });

          this.$http.get('/getTopTen').then(response => {
            this.topTen = response.data.classement;
          }).catch(() => {

          });
        },
        methods: {
          getNews: function(move) {
            this.newsOffset += move;
            if(this.newsOffset < 0) {
              this.newsOffset = 0;
            }
            this.$http.post('/getAllNews', {"offset": this.newsOffset}).then(response => {
              this.lastNews = response.data;
              if(this.newsOffset > 0) {
                location.hash = "#row_news";
              }
            }).catch(() => {

            });
          },
          sendContact: function() {
            if(this.valid) {
              this.$http.post('/contactMail', {form: this.contactInfo}).then(response => {
                if(response.data.success) {
                  this.$notify({
                    group: 'spore',
                    title: 'Super !',
                    text: 'Votre email a bien été envoyé. Merci !',
                    type: 'success'
                  });
                  this.contactInfo = {
                    "name":"",
                    "subject":"",
                    "message":"",
                    "email":""
                  };
                } else {
                  this.$notify({
                    group: 'spore',
                    title: 'Erreur...',
                    text: 'Un problème est survenue lors de l\'envoi de votre email. Merci de réessayer ultérieurement.',
                    type: 'error'
                  });
                }
              }).catch(() => {

              });
            } else {
              this.$notify({
                group: 'spore',
                title: 'Erreur...',
                text: 'Veuillez renseigner les champs obligatoires..',
                type: 'error'
              });
            }
          },
          managePremium: function() {
            this.$http.get('/stripe-portal').then(response => {
              if(response.data.url) {
                document.location.replace(response.data.url);
              }
            }).catch(() => {

            });
          }
        }
    }
</script>
<style scoped>
.contest-theme {
  font-size: 2em;
  font-style: italic;
  margin: 10px;
}
.contest-theme.no-theme {
  line-height: 45px;
}
.bordered {
  border: 2px solid #6f5248;
}
.bordered-right {
  border-right: 1px solid #dadada;
}
.warning-spore {
  color: #9e9e9e;
  font-style: italic;
  font-size: 16px;
}
.spore-text {
  line-height: 40px;
  color: #666666;
}
.small-day {
  font-size: 0.45em;
  color: #3E4147 !important;
  font-weight: lighter;
}
.card-contest {
  height: 350px;
}
.no-contest-container {
  margin-top: 50px;
}
.italic {
  font-style: italic;
}
.theme-container {
  text-transform: uppercase;
  font-weight: bold;;
}
@media screen and (max-width: 640px) {
  .card-contest {
    height: auto;
  }
  .no-contest-container {
    margin-top: 0;
    background: #DFCCAB;
  }
}
</style>
