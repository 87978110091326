<template>
  <v-container v-if="leaderBoard">
    <v-row>
      <v-col cols="12">
        <h1>Classement</h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <p class="long-text">Choisissez la saison :</p>
        <p class="long-text"><v-select
          :items="items"
          v-model="currentItem"
          item-text="label"
          item-value="value"
          dense
          filled
        ></v-select></p>
      </v-col>
    </v-row>
    <v-row  v-if="!leaderBoard.length">
      <v-col cols="12">
        <p class="long-text theme">Aucun classement à l'heure actuelle. Peut-être la saison vient-elle de commencer, ou peut-être ne l'avons nous pas encore mis à jour. Revenez régulièrement.</p>
      </v-col>
    </v-row>
    <v-row v-if="leaderBoard.length">
      <v-col cols="12">
        <v-simple-table width="500" dense>
          <template v-slot:default>
            <thead>
            <tr>
              <th class="text-left bkg-orange d-none d-md-table-cell"></th>
              <th class="text-left bkg-orange d-none d-md-table-cell">
                <span class="long-text color-white">&nbsp;</span>
              </th>
              <th class="text-left bkg-orange">
                <span class="long-text color-white">Nom</span>
              </th>
              <th class="text-left bkg-orange">
                <span class="long-text color-white">Points</span>
              </th>
            </tr>
            </thead>
            <tbody>
            <tr
                v-for="(item, index) in leaderBoard"
                :key="item.title"
            >
              <td :class="index==2 ? 'bottom-bordered' : ''">
                <div v-show="index==0" class="text-center"><img src="https://tavernedesspores.fr/img/gold_cup" width="50px"/></div>
                <div v-show="index==1" class="text-center"><img src="https://tavernedesspores.fr/img/silver_cup" width="50px"/></div>
                <div v-show="index==2" class="text-center"><img src="https://tavernedesspores.fr/img/bronze_cup" width="50px"/></div>
                <div v-show="index>2" class="color-orange fs-30 text-center">{{ index + 1 }}°</div>
              </td>
              <td class="pa-5 d-none  d-md-table-cell" :class="index==2 ? 'bottom-bordered' : ''">
                <v-avatar
                    color="white"
                    size="70"
                >
                  <v-img :src="item.image ? 'https://tavernedesspores.fr/uploads/' + item.image : 'https://tavernedesspores.fr/uploads/default.png'"></v-img>
                </v-avatar>
              </td>
              <td :class="index==2 ? 'bottom-bordered' : ''">
                <span class="long-text" v-if="item.is_public == '0'">{{ item.name }}</span>
                <span v-else><router-link class="long-text" :to="'/auteur/' + item.id_user" title="Voir le profil">{{ item.name }}</router-link></span>
              </td>
              <td :class="index==2 ? 'bottom-bordered' : ''"><span class="long-text">{{ item.points }}</span></td>
            </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  name: 'App',

  components: {
  },

  data: () => ({
    leaderBoard: null,
    items: [
      {value: 'now', label: 'Saison en cours'},
      {value: '2024', label: 'Saison 2024'},
      {value: '2023', label: 'Saison 2023'},
      {value: '2022', label: 'Saison 2022'},
      {value: '2021', label: 'Saison 2021'},
      {value: '2019', label: 'Saison 2019'},
      {value: '2018', label: 'Saison 2018'}
    ],
    currentItem: 'now'
  }),
  mounted() {
    this.$http.get('/getClassement').then((response) => {
        this.leaderBoard = response.data.classement;
      }).catch(() => {

      });
  },
  methods: {

  },
  watch: {
    // whenever question changes, this function will run
    currentItem: function (newItem) {
      const url = newItem !== 'now' ? '/getClassement/' + newItem : '/getClassement';
      this.$http.get(url).then(response => {
        this.leaderBoard = response.data.classement;
      }).catch(() => {

      });
    }
  }
};
</script>
<style scoped>
@media screen and (max-width: 640px) {
  .long-text {
    font-size: 1em;
  }
}
tr:nth-child(even) {
  background-color: #e2e2e2;
}
.bottom-bordered {
  border-bottom: 3px solid #6f5248 !important;
}
</style>