<template>
    <v-container class="read-mode-container" style="margin-top: 75px">
        <div class="filter-bar d-flex justify-space-between align-baseline">
            <div class="select-tag">
                <v-select
                    v-model="currentTag"
                    :items="filterTagList"
                    item-text="label"
                    item-value="value"
                    label="Filtrer par tag"
                    @change="loadSpore()"
                    dense
                    clearable
                    :disabled="isLoading || currentSpore.id == 0"
                    ref="tagSelect"
                ></v-select>
            </div>
            <div class="d-flex align-center fav-container">
                <div class="mr-4" v-if="favOnly && currentSpore && currentSpore.id !== -1">{{ favOffset + 1 }} / {{ currentSpore.tot }}</div>
                <v-tooltip left color="primary">
                    <template v-slot:activator="{ on, attrs }">
                        <div v-bind="attrs"
                        v-on="on">
                            <v-switch
                                v-model="favOnly"
                                color="success"
                                @change="loadSpore()"
                                :disabled="isLoading || currentSpore.id == 0"
                                >
                                <template v-slot:label>
                                    <v-icon :color="favOnly ? 'pink' : ''">mdi-heart</v-icon>
                                </template>
                            </v-switch>
                        </div>
                    </template>
                    <span>Afficher seulement mes favoris</span>
                </v-tooltip>
            </div>
        </div>
        <div class="text-with-image" @touchstart="startTouch" @touchend="endTouch">
            <div class="d-flex justify-space-between mb-5 tag-container">
                <div class="d-flex contest"><span v-if="currentSpore.id != 0 && currentSpore.id !== -1" class='label label-default mr bkg-orange' v-html="currentSpore.contest_name + ' : ' + currentSpore.theme"></span></div>
                <div class="d-flex justify-end gap-1 tags" v-if="currentSpore.tags.length > 0">
                    <span class='label label-default mr bkg-orange' v-for="tag in currentSpore.tags" :key="tag.tag">{{ tag.tag }}</span>
                </div>
                <div class="text-center d-block d-sm-none mt-1 author"><small>{{ currentSpore.created_at }}</small></div>
            </div>
            <div class="image-container">
                <div class="disclaimer-img" v-if="currentSpore.img.indexOf('enseigne.png') > -1 && currentSpore.id != 0 && currentSpore.id != -1">Illustration à venir</div>
                <img :src="currentSpore.img" class='thumbnail'/>
            </div>
			<h2 class="title" :class="currentSpore.id == -1 ? 'text-center pt-15' : ''">{{ currentSpore.title }}</h2>
			<p class="body" v-html="currentSpore.text"></p>
            <div class="d-flex justify-space-between align-center footer-container">
                <div class="d-flex gap-1">
                    <v-icon title="Mettre en favori" class="pointer" :color="currentSpore.liked || currentSpore.liked == '1' ? 'pink' : ''" @click="like()">mdi-heart</v-icon>
                    <v-icon title="Signaler un problème" class="pointer" @click="openReport()">mdi-account-voice</v-icon>
                    <v-icon v-if="currentSpore.readed == '1'" title="Déjà lue !" class="pointer" color="green">mdi-check-all</v-icon>
                </div>
                <div>
                    <span class="author" v-if="currentSpore.is_public=='0'"><small class="d-none d-sm-inline">{{ currentSpore.created_at }} - </small>{{ currentSpore.name }}</span>
                    <span class="author" v-else><small class="d-none d-sm-inline">{{ currentSpore.created_at }}</small> - <router-link title="Voir le profil" class="author-link" :to="'/auteur/' + currentSpore.id_user">{{ currentSpore.name }}</router-link></span>
                </div>
            </div>
		</div>
        <div class="d-flex next-button" :class="favOnly ? 'justify-space-between' : 'justify-end'">
            <v-btn v-if="currentSpore.id != 0 && currentSpore.id != -1 && favOnly" class="bkg-orange" small :disabled = "isLoading || favOffset === 0"
            :loading="isLoading" raised @click="previous()">
                <v-icon
                    left
                    color="white"
                >
                    mdi-arrow-left
                </v-icon>
                <span class="color-white" >Précédent</span>
            </v-btn>
            <v-btn v-if="currentSpore.id != 0 && currentSpore.id != -1" class="bkg-orange" small :disabled = "isLoading"
            :loading="isLoading" @click="next()" raised>
                <span class="color-white" >Suivant</span>
                <v-icon
                    right
                    color="white"
                >
                    {{ favOffset + 1 == currentSpore.tot ? 'mdi-reload' : 'mdi-arrow-right'}}
                </v-icon>
            </v-btn>
        </div>
        <v-dialog
        v-model="reportDialog"
        width="500"
        >
        <v-card>
            <v-card-title class="text-h5 grey lighten-2">
                Signaler la Spore
            </v-card-title>

            <v-card-text>
                <p class="text-justify mt-5">
                    Vous pouvez nous remonter toute anomalie concernant cette Spore. Nous vous remercions de votre aide pour améliorer la qualité du mode lecture !
                </p>
                <p>
                    <v-select
                        v-model="reportReason"
                        :items="reportReasons"
                        item-text="label"
                        item-value="value"
                        label="Raison du signalement"
                        outlined
                        class="mt-5"></v-select>
                </p>
                <p class="text-right">
                    <v-btn
                        text
                        @click="reportDialog = false"
                    >
                        Annuler
                    </v-btn>
                    <v-btn
                        class="bkg-orange"
                        @click="report()"
                        :disabled = "isLoading"
                        :loading="isLoading"
                    >
                        <span class="color-white">Envoyer</span>
                    </v-btn>
                </p>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="primary"
                text
                @click="dialog = false"
            >
                I accept
            </v-btn>
            </v-card-actions>
        </v-card>
        </v-dialog>
    </v-container>
  </template>
  <script>
  export default {
    name: 'App',
  
    components: {
    },
  
    data: () => ({
      currentSpore: {
        id: 0,
        title: 'Un mode lecture ?',
        text: 'Vous souhaitez lire toutes les Spores du site, dans un mode amélioré ? Pouvoir mettre de côté celles que vous aimez ? C\'est ce que vous propose le nouveau mode lecture !<br/><br/>Ce que vous lisez actuellement a la même présentation que le mode lecture.<br/><br/>Grâce à lui, vous pourrez sauvegarder vos Spores préférées et les relire quand vous voudrez, tout en étant sûr de ne jamais relire une Spore que vous auriez déjà lue sans la mettre en favori.<br/><br/>Avec une compatibilité mobile accrue, vous disposez de lecture rapide à tout instant !<br/><br/>Ce nouveau mode lecture est accessible uniquement <a href="https://tavernedesspores.fr/#/infos-premium" class="long-text" style="font-size: 20px">aux membres Premium</a> de la Taverne.',
        img: 'https://tavernedesspores.fr/img/enseigne.png',
        name: 'La Taverne des spores',
        tags: [{tag:'Fantastique'}, {tag:'Premium'}],
        created_at: new Date().toLocaleString(),
        is_public: '0'
      },
      isLoading: false,
      reportReasons: [
        {value: 1, label: 'Je trouve les tags incorrects/inappropriés'},
        {value: 2, label: 'Je trouve l\'illustration incorrecte/inappropriée'},
        {value: 3, label: 'Je trouve la spore mal écrite, mal orthographiée'}
      ],
      reportDialog: false,
      reportReason: null,
      favOnly: false,
      filterTagList: [
        {value: 'fantastique', label: 'Fantastique'},
        {value: 'science-fiction', label: 'Science-fiction'},
        {value: 'horreur', label: 'Horreur'},
        {value: 'romance', label: 'Romance'},
        {value: 'policier', label: 'Policier'},
        {value: 'suspense', label: 'Suspense'},
        {value: 'aventure', label: 'Aventure'},
        {value: 'comedie', label: 'Comédie'},
        {value: 'drame', label: 'Drame'}
      ],
      currentTag: null,
      favOffset: 0,
      touchStartX: 0,
      touchEndX: 0
    }),
    mounted() {
        if(this.$store.state.isConnected) {
            this.isLoading = true;
            this.loadSpore(true);
        }
    },
    methods: {
      loadSpore: function(goToHome = false) {
        this.$refs.tagSelect.blur();
        this.isLoading = true;
        let url = this.currentTag ? '/getReadMode/' + this.currentTag : '/getReadMode';
        if(this.favOnly) {
            url = !this.currentTag ? '/getFavReadMode/' + this.favOffset : '/getFavReadMode/' + this.favOffset + '/' + this.currentTag;
        }
        this.$http.get(url).then(response => {
            if(response.data.success) {
                if(response.data.spore) {
                    this.currentSpore = response.data.spore;
                    window.scrollTo(0, 0);
                } else {
                    if(!this.favOnly || this.currentTag) {
                        this.currentSpore = {
                            id: -1,
                            title: 'Aucune Spore ne correspond à votre recherche.',
                            text: '<br/><br/><br/><br/><br/>',
                            img: 'https://tavernedesspores.fr/img/enseigne.png',
                            name: 'La Taverne des spores',
                            tags: [],
                            created_at: new Date().toLocaleString(),
                            is_public: '0',
                            readed: '0',
                            liked: false
                        }
                    } else if(this.favOnly && this.favOffset === 0) {
                        this.currentSpore = {
                            id: -1,
                            title: 'Vous n\'avez pour l\'instant aucun favori.',
                            text: '<br/><br/><br/><br/><br/>',
                            img: 'https://tavernedesspores.fr/img/enseigne.png',
                            name: 'La Taverne des spores',
                            tags: [],
                            created_at: new Date().toLocaleString(),
                            is_public: '0',
                            readed: '0',
                            liked: false
                        }
                    }
                }
            } else {
                if(goToHome) {
                    this.router.push('/home');
                }
            }
            this.isLoading = false;
        }).catch(function(response){
            this.$notify({
                group: 'spore',
                title: 'Erreur...',
                text: response.data.message,
                type: 'error'
            });
            this.isLoading = false;
        });
      },
      next: function() {
        // Les favs se lisent en boucle
        if(this.favOnly) {
            this.favOffset++;
            if(this.favOffset >= this.currentSpore.tot) {
                this.favOffset = 0;
            }
        }
        this.loadSpore();
      },
      previous: function() {
        if(this.favOffset > 0) {
            this.favOffset--;
            this.loadSpore();
        }
      },
      report: function() {
        if(this.reportReason) {
            this.isLoading = true;
            this.$http.post( '/reportSpore', {id: this.currentSpore.id, report: this.reportReason}).then(response => {
                if(response.data.success) {
                    this.$notify({
                        group: 'spore',
                        title: 'Merci !',
                        text: 'Votre signalement a bien été pris en compte.',
                        type: 'success'
                    });
                    this.reportDialog = false;
                } else {
                    this.$notify({
                        group: 'spore',
                        title: 'Erreur...',
                        text: 'Une erreur est survenue. Merci de réessayer plus tard.',
                        type: 'error'
                    });
                }
                this.isLoading = false;
            }).catch(function(){
                this.$notify({
                    group: 'spore',
                    title: 'Erreur...',
                    text: 'Une erreur est survenue. Merci de réessayer plus tard.',
                    type: 'error'
                });
                this.isLoading = false;
            });
        }
      },
      like: function() {
        if(this.currentSpore.id != 0 && this.currentSpore.id != -1) {
            this.isLoading = true;
            this.currentSpore.liked = !this.currentSpore.liked;
            this.$http.post( '/likeSpore', {id: this.currentSpore.id, like: this.currentSpore.liked}).then(response => {
                if(response.data.success) {
                    this.$notify({
                        group: 'spore',
                        title: 'OK',
                        text: 'Le statut favori de la Spore a été mis à jour.',
                        type: 'success'
                    });
                } else {
                    this.$notify({
                        group: 'spore',
                        title: 'Erreur...',
                        text: 'Une erreur est survenue. Merci de réessayer plus tard.',
                        type: 'error'
                    });
                }
                this.isLoading = false;
            }).catch(function(){
                this.$notify({
                    group: 'spore',
                    title: 'Erreur...',
                    text: 'Une erreur est survenue. Merci de réessayer plus tard.',
                    type: 'error'
                });
                this.isLoading = false;
            });
        }
      },
      openReport: function() {
        if(this.currentSpore.id != 0 && this.currentSpore.id != -1) {
            this.reportDialog = true;
        }
      },
      startTouch(event) {
        this.touchStartX = event.touches[0].clientX; // Capture la position du début du swipe
      },
      endTouch(event) {
        if(this.$store.state.isPremium) {
            this.touchEndX = event.changedTouches[0].clientX; // Capture la position de fin du swipe

            if (this.touchStartX - this.touchEndX > 50) { // Seuil pour éviter les faux swipes
                const textElement = document.querySelector('.text-with-image');
                textElement.classList.add('swipe-left');

                setTimeout(() => {
                    this.next();
                    textElement.classList.remove('swipe-left');
                }, 300);
            }
        }
      }
    }
  };
  </script>
  <style scoped>
    .read-mode-container {
        width: 800px;
        margin: auto;
    }
    .text-with-image {
        width: 800px; /* Largeur maximale */
        border: 1px solid #ddd; /* Bordure autour */
        padding: 10px; /* Espacement interne */
        border-radius: 8px; /* Coins arrondis */
        background-color: #f9f9f9; /* Couleur de fond */
        overflow: hidden; /* Assure que les contenus flottants sont bien inclus */
        position: relative;
        margin: auto;
        box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
        transition: transform 0.3s ease-in-out;
    }
    .swipe-left {
        transform: translateX(100%);
        opacity: 0;
    }
    .author small {
        color: #888; /* Couleur grise pour le texte */
        font-size: 13px; /* Taille du texte */
        font-style: italic;
        font-weight: 100;
    }
    .thumbnail {
        float: left; /* Place l'image à gauche */
        width: 250px; /* Largeur fixe de l'image */
        height: 250px; /* Hauteur fixe de l'image */
        margin-right: 10px; /* Espace entre l'image et le texte */
        margin-bottom: 0 !important;
        object-fit: cover; /* Ajuste l'image sans déformation */
        border-radius: 4px; /* Coins arrondis pour l'image */

        display: block;
        padding: 4px;
        border: 1px solid #ddd;
        -webkit-transition: border .2s ease-in-out;
        -o-transition: border .2s ease-in-out;
        transition: border .2s ease-in-out;
    }

    .title {
        font-size: 25px !important; /* Taille du titre */
        font-weight: bold; /* Texte en gras */
        margin: 0; /* Supprime les marges autour du titre */
        color: #333; /* Couleur du texte */
        text-transform: lowercase;
    }

    .title::first-letter {
        text-transform: capitalize;
    }

    .body {
        font-size: 20px; /* Taille du texte */
        margin: 10px 0; /* Espace autour du texte */
        color: #555; /* Couleur du texte */
        text-align: justify; /* Alignement justifié pour un rendu soigné */
        min-height: 200px;
    }

    .author {
        font-size: 20px; /* Taille de l'auteur */
        color: #888; /* Couleur grise pour le texte de l'auteur */
        text-align: right; /* Aligné à droite */
        display: block; /* Affiche comme un bloc pour forcer l'alignement */
        clear: both; /* Assure que l'auteur se positionne sous tout le contenu */
        font-weight: bold;
        text-transform: capitalize;
    }
    .label {
        text-transform: capitalize;
        font-size: 100%;
        display: inline;
        padding: .2em .6em .3em;
        line-height: 1;
        color: #fff;
        text-align: center;
        white-space: nowrap;
        vertical-align: baseline;
        border-radius: .25em;
        /* background-color: #777; */
        font-family: 'Oswald';
    }
    .next-button {
        width: 800px;
        margin: auto;
        margin-top: 15px;
    }
    .image-container {
        position: relative;
        width: auto;
        height: auto;
    }
    .disclaimer-img {
        position: absolute;
        top: 5px;
        left: 5px;
        background: white;
        font-style: italic;
    }
    .filter-bar {
        width: 800px;
        margin: auto;
    }
    .footer-container {
        margin-top: 20px;
    }
    .v-input.v-select {
        color: black !important;
    }
    @media screen and (max-width: 800px) {
        .read-mode-container {
            width: 100%;
            margin: auto;
        }
        .text-with-image {
            width: 100%;
        }
        .filter-bar {
            width: 100%;
            flex-direction: column-reverse;
        }
        .next-button {
            width: 100%;
        }  
        .thumbnail {
            width: 100%;
            height: auto;
        }      
        .tag-container {
            width: 100%;
            flex-direction: column;
        }
        .tag-container .tags {
            margin-top: 10px;
            justify-content: center !important;
        }
        .tag-container .contest {
            justify-content: center !important;
        }
        .footer-container {
            flex-direction: column-reverse;
            margin-top: 0;
        }
        .fav-container {
            justify-content: end;
            width: 100%;
        }
        .select-tag {
            width: 100%;
        }
    }
  </style>