<template>
    <div class="trophy-container">
        <ul>
            <li :style="trophy.value ? '--accent-color:#6f5248' : '--accent-color:#ccc'" v-for="(trophy, index) in trophies" :key="index">
                <div v-if="trophy.value" class="icon"><img :src="'https://tavernedesspores.fr/img/' + trophy.img" alt="Badge" /><div class="shiner"></div></div>
                <div v-else class="icon"> </div>

                <div v-if="trophy.value" class="title-trophy">{{ trophy.title }}</div>
                <div v-else class="title-trophy">?</div>

                <div v-if="trophy.value" class="descr">
                    {{ trophy.descr }}
                    <div v-if="trophy.data" class="small">{{ trophy.data }}</div>
                </div>
                <div v-else class="descr">
                    ???
                </div>
            </li>
        </ul>
    </div>
</template>
<script>
export default {
name: 'Trophy',

components: {
},
props: {
    trophies: Array
},
data: () => ({
    
}),
mounted() {
    
},
methods: {

}
};
</script>
<style scoped>
ul {
    --col-gap: 2rem;
    --barH: 1rem;
    --roleH: 2rem;
    --flapH: 4rem;

    /* width: min(60rem, 90%); */
    /* margin-inline: auto; */

    display: flex;
    /* flex-wrap: wrap; */

    gap: var(--col-gap);
    padding-inline: calc(var(--col-gap) / 2);

    /* justify-content: center; */
    align-items: flex-start;
    list-style: none;
}

ul li {
    min-width: 9em;
    display: grid;
    grid-template:
        "role"
        "icon"
        "title"
        "descr";
    position: relative;
    align-items: flex-start;
    gap: .5rem;
    padding-block-end: calc(var(--flapH) + 1rem);
    text-align: center;
    background: var(--accent-color);
    background-image: linear-gradient(rgba(0, 0, 0, 0.6) var(--roleH),
            rgba(0, 0, 0, 0.4) calc(var(--roleH) + 0.5rem),
            rgba(0, 0, 0, 0) calc(var(--roleH) + 0.5rem + 5rem));
    clip-path: polygon(calc(var(--col-gap) / -2 - 5px) 0,
            calc(100% + var(--col-gap) / 2 + 5px) 0,
            calc(100% + var(--col-gap) / 2 + 5px) calc(100% - var(--flapH)),
            50% 100%,
            calc(var(--col-gap) / -2 - 5px) calc(100% - var(--flapH)));
    /*max-height: 115px;*/
    transition: max-height .9s ease-in-out;
    box-shadow: 5px 5px 15px -2px #9f9f9f;
    border-left: 3px dashed rgb(48 51 30);
    border-right: 3px dashed rgb(48 51 30);
}

li img {
    width: 80%;
    border-radius: 50%;
    box-shadow: 5px 5px 15px -2px #757575;
    border: 2px solid #252525;
}

/* bar */
ul li::before {
    content: "";
    grid-area: role;
    height: var(--barH);
    width: calc(110% + var(--col-gap));
    margin-left: calc(var(--col-gap) / -2);
    margin-top: calc(var(--roleH) / 2 - var(--barH) / 2);
    background: grey;
    z-index: -1;
    background-image: linear-gradient(rgba(255, 255, 255, 0.4),
            rgba(255, 255, 255, 0.2) 30%,
            rgba(255, 255, 255, 0.1) 40%,
            rgba(0, 0, 0, 0.1) 60%,
            rgba(0, 0, 0, 0.2) 70%,
            rgba(0, 0, 0, 0.4));
}

/* role */
ul li::after {
    content: "";
    grid-area: role;
    background: var(--accent-color);
    background-image: linear-gradient(rgba(255, 255, 255, 0.4),
            rgba(255, 255, 255, 0.2) 30%,
            rgba(255, 255, 255, 0.1) 40%,
            rgba(0, 0, 0, 0.1) 60%,
            rgba(0, 0, 0, 0.2) 70%,
            rgba(0, 0, 0, 0.4));
    height: var(--roleH);
}

ul li .icon,
ul li .title-trophy,
ul li .descr {
    padding-inline: 1rem;
    color: white;
    text-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.5);
}

ul li .icon {
    position: relative;
}

ul li .title-trophy {
    font-size: 1.1rem;
    font-weight: 700;
    font-family: Oswald;
}

ul li .descr {
    font-size: 0.8rem;
    font-style: italic;
}

ul li .descr .small {
    color: #939393;
    margin-top: 10px;
    font-size: 9px;
}

li .shiner {
    position: absolute;
    top: 5%;
    left: 0;
    width: 100%;
    height: 90%;
    transform: rotate(30deg);
    background: linear-gradient(to right,
            rgba(255, 255, 255, 0) 0%,
            rgba(255, 255, 255, 0.13) 77%,
            rgba(255, 255, 255, 0.5) 92%,
            rgba(255, 255, 255, 0.0) 100%);
    opacity: 0;
    animation: shine 3s infinite linear;
    z-index: 1;
    filter: blur(10px);
}

.trophy-container {
    overflow-x: auto;
    width: 100%;
    padding-bottom: 15px;
}

@keyframes shine {
    0% {
        transform: translateX(-100%);
        opacity: 0;
    }

    35% {
        opacity: 0;
    }

    50% {
        opacity: 0.6;
    }

    100% {
        transform: translateX(0);
        opacity: 0;
    }
}
</style>